import React, { useEffect, useState } from "react";

import { Row, Col, Button, Form, CardDeck, CardColumns } from "react-bootstrap";
// import "./styles.css";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import icons8_data_anal from "../../img/icons8-data-analysis-66.png";
import focusnext_main_img from "../../img/focusnext-main-img.png";
import icons_circled_play from "../../img/icons8-circled-play.gif";
import icons8_agenda_64 from "../../img/icons8-agenda-64.png";
import user_branches_banner from "../../img/user_branches_banner.png";
import icons8_combo_chart_48 from "../../img/icons8-combo-chart-48.png";
import icons8_customer_support_48 from "../../img/icons8-customer-support-48.png";
import { HomeStyled } from "./Home.styled";

const Home = () => {
  const [addressEnabled, setAddressEnabled] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  // const { allProducts } = useSelector(({ productCart }) => productCart);
  useEffect(() => {
    console.log("vinod");
  }, []);

  const gotoSignupPage = (url) => {
    history.push(`/${url}`);
  };
  return (
    <>
      <HomeStyled>
        <div className="focusnext-home-page-container">
          <div className="focusnext-home-wrapper">
            <div className="bg-color-shadow1">
              <div className="main-top-sec">
                <div className="main-top-left">
                  <div className="main-top-left-content">
                    <h2>Drive More Customers Through Digital</h2>
                    {/* <p>
                      Chap fantastic skive off chancer knees up starkers easy
                      peasy up the kyver David, bleeding the BBC tomfoolery
                      chimney.!
                    </p> */}
                    <div className="main-top-left-btn">
                      <button
                        onClick={() => {
                          gotoSignupPage("aboutUs");
                        }}
                      >
                        Our Features
                      </button>
                    </div>
                  </div>
                </div>
                <div className="main-top-left">
                  <img src={focusnext_main_img} />
                </div>
              </div>
            </div>
            <div className="bg-color-shadow">
              <div className="features-top-sec">
                <div className="features-top-left">
                  <div className="features-top-left-content">
                    <p className="our-features-text">OUR FEATURES</p>
                    <h2>Revolutionize your online business today!</h2>
                    <p>
                      The business carrent account that is your Accounting
                      software. A simple way to run your business
                    </p>
                    <div>
                      <span>
                        <img src={icons_circled_play} /> WATCH VIDEO
                      </span>
                    </div>
                  </div>
                </div>
                <div className="features-top-right">
                  <div className="content-sec-all">
                    <div className="content-sec-row">
                      <div className="img-sec-top">
                        <img src={icons8_data_anal} />
                      </div>
                      <h3>Data Analytics</h3>
                      <p>
                        The business carrent account that is your Accounting
                        software. A simple way to run your business
                      </p>
                    </div>
                    <div className="content-sec-row">
                      <div className="img-sec-top">
                        <img src={icons8_agenda_64} />
                      </div>
                      <h3>Unlimited Bandwidth</h3>
                      <p>
                        The business carrent account that is your Accounting
                        software. A simple way to run your business
                      </p>
                    </div>
                    <div className="content-sec-row">
                      <div className="img-sec-top">
                        <img src={icons8_combo_chart_48} />
                      </div>
                      <h3>Intellegent Chart</h3>
                      <p>
                        The business carrent account that is your Accounting
                        software. A simple way to run your business
                      </p>
                    </div>
                    <div className="content-sec-row">
                      <div className="img-sec-top">
                        <img src={icons8_customer_support_48} />
                      </div>
                      <h3>customer friendly</h3>
                      <p>
                        The business carrent account that is your Accounting
                        software. A simple way to run your business
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-color-shadow1">
              <div className="users-top-sec">
                <div className="users-top-left">
                  <img src={user_branches_banner} />
                </div>
                <div className="users-top-right">
                  <div className="users-top-left-content">
                    <h2>Unlimited users Access</h2>
                    <p>
                      Create unlimited users with just single license. Set up
                      your entire network from day one with any of our licenses
                      and upgrade only on more usage. A true pay as you go
                      model.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-color-shadow">
              <div className="reports-top-sec">
                <div className="reports-top-left">
                  <div className="reports-top-left-content">
                    <h2>Unlimited Reports</h2>
                    <p>
                      For unlimited users you can get unlimited reports user
                      wise, get daily waise, weekly wise and monthly wise. For
                      Profiles you can get reports products wise and Brand wise,
                      etc...
                    </p>
                  </div>
                </div>
                <div className="reports-top-right">
                  <img src={user_branches_banner} />
                </div>
              </div>
            </div>
            <div className="bg-color-shadow1" id="pricingSection">
              <div className="sign-up-top-sec">
                <h3>Sign up now for 365 days free trail.</h3>
                <p>
                  Sign up now as a Store owner and register unlimited user to
                  access at one subscription and create unlimited Profiles.
                </p>
                <button
                  onClick={() => {
                    gotoSignupPage("signup");
                  }}
                >
                  Start Now
                </button>
                <div>
                  <p>
                    Already Registered? <a href="/signin">Log in</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HomeStyled>
    </>
  );
};

export default Home;
