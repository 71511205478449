import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../pages/Home";
// import Signin from "../pages/Signin";
import SignIn from "../pages/SignIn";

import PrivacyPolicy from "./../pages/PrivacyPolicy";
import ContactUS from "./../pages/ContactUS";
import TermsOfService from "./../pages/TermsOfService";
import SignUp from "./../pages/SignUp/SignUp";
import Dashboard from "../pages/Dashboard/Dashboard";
import MyProfile from "../pages/MyProfile";
import { useLocation } from "react-router-dom";
import UsersList from "./../pages/UsersList/UsersList";
import StoresList from "./../pages/StoresList/StoresList";
import StoresReport from "./../pages/StoresReport/StoresReport";
import MyAccount from "./../pages/userManagement/index";
import CustomersList from "./../pages/CustomersList/CustomersList";
import ChangePassword from "./../pages/ChangePassword/ChangePassword";
import PrintPageSetting from "./../pages/PrintPageSetting/PrintPageSetting";
import AboutUs from "./../pages/AboutUs/AboutUs";
import EmailsReport from "./../pages/EmailsReport/EmailsReport";
import Testing from "../pages/Testing/Testing";
import CreateOrder from "../pages/CreateOrder/CreateOrder";
const MainRoutes: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Switch>
      <Route exact path="/" component={Home} />

      <Route exact path="/contactUS" component={ContactUS} />
      <Route exact path="/signup" component={SignUp} />
      <Route exact path="/Signin" component={SignIn} />
      <Route exact path="/Testingapp" component={Testing} />
      <Route exact path="/TermsOfService" component={TermsOfService} />
      <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />

      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/changepassword" component={ChangePassword} />
      <Route exact path="/myProfile" component={MyProfile} />
      <Route exact path="/usersList" component={UsersList} />
      <Route exact path="/customersList" component={CustomersList} />
      <Route exact path="/storesList" component={StoresList} />
      <Route exact path="/storesReport" component={StoresReport} />

      <Route exact path="/storesList1" component={MyAccount} />
      <Route exact path="/printpagesetting" component={PrintPageSetting} />
      <Route exact path="/AboutUs" component={AboutUs} />
      <Route exact path="/EmailsReport" component={EmailsReport} />
      <Route exact path="/createOrder" component={CreateOrder} />
    </Switch>
  );
};

export default MainRoutes;
