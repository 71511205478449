import { EmailsReportStyled } from "./EmailsReport.styled";
import React, { useState, useEffect } from "react";
// import { getApprovedUsersCall } from "./../../redux/slices/serviceManagement/serviceManagementService";
// import { getApprovedUsersCall } from "./../../redux/slices/storeManagement/serviceManagementService";
// import { LOGINID } from "./../../Scenes/common";
import { useDispatch, useSelector } from "react-redux";
import {
  getStoreWiseJobCardReportAPi,
  getAllStoresReportAPi,
} from "../../redux/slices/Reports/ReportsService";

import {
  Row,
  Col,
  Button,
  CardColumns,
  Form,
  Tab,
  Nav,
  Modal,
  Dropdown,
} from "react-bootstrap";
import { ReactComponent as BlockIcon } from "./../../assets/blockIcon.svg";
import { ReactComponent as RedBlockIcon } from "./../../assets/redBlockIcon.svg";
import { ReactComponent as ChevronLeft } from "./../../assets/chevronLeft.svg";
import { ReactComponent as HorizontalDots } from "./../../assets/HorizontalDots2.svg";
// import "./styles.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import CustomModal from "../../components/CustomModel";
import {
  getbusinessuserbyidCall,
  blockCustomer,
  getcustomeraddressCall,
  getPendingUsersCall,
  getRejectedUsersCall,
  getApprovedUsersCall,
  getPaymentDetailsCall,
  customerApprovelCall,
  customerRejectCall,
} from "../../redux/slices/storeManagement/storeManagementService";
// import { getApprovedUsersCall } from "./../../redux/slices/serviceManagement/serviceManagementService";
import SearchComponent from "../../components/SearchComponent";
// import ImagePopup from "./../../components/ImagePopup";
import moment from "moment";
import { LOGINID } from "../../Scenes/common";

const EmailsReport = () => {
  const dispatch = useDispatch();
  const [usersList, setUsersList] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
  ]);
  const [showAllUsers, setShowAllUsers] = useState(true);
  const [allUsers, setAllUsers] = useState([]);
  const [approvedUsers, setApprovedUsers] = useState([]);
  const [pendingUsers, setPendingUsers] = useState([]);
  const [rejectedUsers, setRejectedUsers] = useState([]);
  const [showApprovedUsers, setShowApprovedUsers] = useState([]);
  const [showBlockUserPopup, setShowBlockUserPopup] = useState(false);
  const [showBlockSuccessUserPopup, setShowBlockSuccessUserPopup] =
    useState(false);
  const [showUnBlockUserPopup, setShowUnBlockUserPopup] = useState(false);
  const [showUnBlockSuccessUserPopup, setShowUnBlockSuccessUserPopup] =
    useState(false);
  const [selectedData, setSelectedData] = useState({} as any);
  const [selectedAddress, setSelectedAddress] = useState([]);
  const [userId, setUserId] = useState("");
  const [showBlockedUsers, setShowBlockedUsers] = useState(false);
  const [show, setShow] = useState(false);
  const [imageUrlForPopup, setImageUrlForPopup] = useState("");
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [showPendingUsersPopup, setShowPendingUsersPopup] = useState(false);
  const [showPendingUsersText, setShowPendingUsersText] = useState("");
  const [selctedType, setSelctedType] = useState("");
  const [selectedPendingUser, setSelectedPendingUser] = useState({} as any);
  const [showRejectUserUnputPopup, setShowRejectUserUnputPopup] =
    useState(false);
  const [purchaseHistory, setPurchaseHistory] = useState([]);

  const [approvedcurrentPage, setApprovedcurrentPage] = useState(1);
  const [pendingcurrentPage, setPendingcurrentPage] = useState(1);
  const [rejectedcurrentPage, setRejectedcurrentPage] = useState(1);

  const [usersPerPage, setUsersPerPage] = useState(15);
  const [pageNumberLimit, setPageNumberLimit] = useState(5);

  const [approvedmaxPageNumberLimit, setApprovedmaxPageNumberLimit] =
    useState(5);
  const [approvedminPageNumberLimit, setApprovedminPageNumberLimit] =
    useState(0);

  const [pendingmaxPageNumberLimit, setPendingmaxPageNumberLimit] = useState(5);
  const [pendingminPageNumberLimit, setPendingminPageNumberLimit] = useState(0);

  const [rejectedmaxPageNumberLimit, setRejectedmaxPageNumberLimit] =
    useState(5);
  const [rejectedminPageNumberLimit, setRejectedminPageNumberLimit] =
    useState(0);

  const [approvedTotalCount, setApprovedTotalCount] = useState(0);
  const [pendingTotalCount, setPendingTotalCount] = useState(0);
  const [rejectedTotalCount, setRejectedTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [eventKeyTypes, setEventKeyTypes] = useState("approved");
  const { todayReports, allStoresReport } = useSelector(
    (state: any) => state.reports
  );
  console.log("allStoresReport", allStoresReport);
  useEffect(() => {
    console.log("vinovvvvv", LOGINID);
    getAllUsersData("onLoad");
    if (LOGINID) {
      const body = {
        startDate: "",
        endate: "",
      };
      dispatch(getStoreWiseJobCardReportAPi(body));
      dispatch(getAllStoresReportAPi());

      // getuserDataid(LOGINID);
    }
  }, []);
  const getAllUsersData = async (pageType?: any) => {
    const testJsonDa = {
      email: "vonod@gmail.com",
      phoneNumber: "9164236553",
      firstName: "vinod",
      lastName: "Tana",
    };
    const testData = [testJsonDa, testJsonDa, testJsonDa, testJsonDa];

    setLoading(true);
    // const resp = await getAllUsers();
    const approved = (await dispatch(
      getApprovedUsersCall({
        approvedcurrentPage,
        usersPerPage,
      })
    )) as any;
    console.log("approved", approved);
    const Pending = (await dispatch(
      getPendingUsersCall({
        pendingcurrentPage,
        usersPerPage,
      })
    )) as any;
    const Rejected = (await dispatch(
      getRejectedUsersCall({
        rejectedcurrentPage,
        usersPerPage,
      })
    )) as any;
    setLoading(false);
    let approvedUsers = approved?.payload || testData,
      pendingUsers = Pending?.payload || testData,
      rejectedUsers = Rejected?.payload || testData;
    setShowApprovedUsers(approvedUsers);
    setApprovedUsers(approvedUsers);
    setPendingUsers(pendingUsers);
    // setRejectedUsers(rejectedUsers);

    if (pageType == "onLoad") {
      setApprovedTotalCount(approved?.totalCount);

      setPendingTotalCount(Pending?.totalCount);

      setRejectedTotalCount(Rejected?.totalCount);
    }
  };
  const hideModel = () => {
    setShowBlockUserPopup(false);
    setShowBlockSuccessUserPopup(false);
    setShowUnBlockSuccessUserPopup(false);
    setShowUnBlockUserPopup(false);
    setShowPendingUsersPopup(false);
  };
  const getuserDataid = async (id: any) => {
    if (id) {
      const resp = (await getbusinessuserbyidCall(id)) as any;
      console.log(resp);
      const respt = resp?.customer;
      selectUserInfo(respt);
    }
  };
  const selectUserInfo = async (user: any) => {
    // window.scrollTo(0, 0);
    // setShowAllUsers(false);
    // setSelectedData(user);
    // // this.setState({ showAllUsers: false, selectedData: user });
    // const getAddress = (await getcustomeraddressCall(user?.id)) as any;
    // // this.setState({ selectedAddress: getAddress?.address });
    // setSelectedAddress(getAddress?.address);
    // const History = (await getPaymentDetailsCall(user?.id)) as any;
    // setPurchaseHistory(History?.data);
    // this.setState({ purchaseHistory: History?.data });
  };
  const selectedUsersType = (e: any) => {
    let value = e.target.value;
    if (value == "all") {
      setShowApprovedUsers(approvedUsers);
    } else if (value == "blocked") {
      let finaalAction = approvedUsers.filter(
        (item: any) => item?.isBlocked == true
      );
      setShowApprovedUsers(finaalAction);
    } else if (value == "unBlocked") {
      let finaalAction = approvedUsers.filter(
        (item: any) => item?.isBlocked == false
      );
      setShowApprovedUsers(finaalAction);
    }
  };
  const blockUser = (user: any) => {
    setShowBlockUserPopup(true);
    setUserId(user.id);
    // this.setState({ showBlockUserPopup: true, userId: user.id });
  };
  const blockUserConform = async () => {
    let id = userId;
    const updatedUsers = showApprovedUsers as any;
    const index = updatedUsers.findIndex((item: any) => item.id == id);
    if (index > 0) {
      updatedUsers[index].isBlocked = true;
      setShowApprovedUsers(updatedUsers);
    }
    const updatedUsers1 = approvedUsers as any;
    const index1 = updatedUsers1.findIndex((item: any) => item.id == id);
    if (index1 > 0) {
      updatedUsers1[index1].isBlocked = true;
      setApprovedUsers(updatedUsers1);
    }

    const body = {
      id: id,
      isBlocked: true,
    };
    let resp = (await blockCustomer(body)) as any;
    if (resp.success) {
      setShowBlockUserPopup(false);
      setShowBlockSuccessUserPopup(true);
      // this.setState({
      //   showBlockUserPopup: false,
      //   showBlockSuccessUserPopup: true,
      // });
      getAllUsersData();
    }
  };
  const unBlockUser = (user: any) => {
    //   this.setState({ showUnBlockUserPopup: true, userId: user.id });
  };
  const unBlockUserConform = async () => {
    //   let id = this.state.userId;
    //   const updatedUsers = this.state.showApprovedUsers;
    //   const index = updatedUsers.findIndex((item) => item.id == id);
    //   if (index > 0) {
    //     updatedUsers[index].isBlocked = false;
    //     this.setState({ showApprovedUsers: updatedUsers });
    //   }
    //   const updatedUsers1 = this.state.approvedUsers;
    //   const index1 = updatedUsers1.findIndex((item) => item.id == id);
    //   if (index1 > 0) {
    //     updatedUsers1[index1].isBlocked = false;
    //     this.setState({ approvedUsers: updatedUsers1 });
    //   }
    //   const body = {
    //     id: id,
    //     isBlocked: false,
    //   };
    //   let resp = await blockCustomer(body);
    //   if (resp.success) {
    //     this.setState({
    //       showUnBlockUserPopup: false,
    //       showUnBlockSuccessUserPopup: true,
    //     });
    //     this.getAllUsersData();
    //   }
  };
  const showImagePopup1 = (image: any) => {
    //   this.setState({ imageUrlForPopup: image, showImagePopup: true });
  };
  const purchaseDetailsInfo = (id: any) => {
    //   this.props.history.push(`/dashboard/orderDetails/${id}`);
  };
  const getPrimaryAdress = (address: any) => {
    let finalAddress = "";
    (address || []).map((user: any, index: any) => {
      console.log(user);
      if (user?.isPrimary) {
        finalAddress = user?.address1 ? `${user?.address1}` : "";
        finalAddress += user?.address2 ? `, ${user?.address2}` : "";
        finalAddress += user?.city ? `, ${user?.city}` : "";
        finalAddress += user?.state ? `, ${user?.state}` : "";
        finalAddress += user?.county ? `, ${user?.county}` : "";
        finalAddress += user?.zipPostalCode ? `, ${user?.zipPostalCode}` : "";
      }
    });
    return finalAddress;
  };

  const getFullAdress = (user1: any) => {
    let finalAddress = "";

    finalAddress = user1?.address1 ? `${user1?.address1}` : "";
    finalAddress += user1?.address2 ? `, ${user1?.address2}` : "";
    finalAddress += user1?.city ? `, ${user1?.city}` : "";
    finalAddress += user1?.state ? `, ${user1?.state}` : "";
    finalAddress += user1?.county ? `, ${user1?.county}` : "";
    finalAddress += user1?.zipPostalCode ? `, ${user1?.zipPostalCode}` : "";

    return finalAddress;
  };
  const actionPendingUser = (type: any, user: any) => {
    setSelectedPendingUser(user);
    setSelctedType(type);
    setShowPendingUsersPopup(true);
    if (type == "Approve") {
      setShowPendingUsersText("Are you sure you want to Approve this Store?");

      // this.setState({
      //   selectedPendingUser: user,
      //   selctedType: type,
      //   showPendingUsersPopup: true,
      //   showPendingUsersText: ,
      // });
    } else {
      setShowPendingUsersText("Are you sure you want to Reject this Store?");

      // this.setState({
      //   selectedPendingUser: user,
      //   selctedType: type,
      //   showPendingUsersPopup: true,
      //   showPendingUsersText: "Are you sure you want to Reject this User?",
      // });
    }
  };
  const clickConformAction = async () => {
    setShowPendingUsersPopup(false);

    // this.setState({ showPendingUsersPopup: false });
    if (selctedType == "Approve") {
      const body = {
        id: selectedPendingUser?.id,
        isApproved: true,
      };
      const resp = (await dispatch(customerApprovelCall(body))) as any;
      if (resp?.payload?.id) {
        // this.setState({
        //   showPendingUsersPopup: true,
        //   selctedType: "",
        //   showPendingUsersText: "You have Approved the User Successfully.",
        // });
        setShowPendingUsersPopup(true);
        setSelctedType("");
        setShowPendingUsersText("You have Approved the Store Successfully.");
        getAllUsersData();
      } else if (
        resp?.errors?.length > 0 &&
        resp?.errors[0] != undefined &&
        resp?.errors[0] != ""
      ) {
        // this.setState({
        //   showPendingUsersPopup: true,
        //   selctedType: "",
        //   showPendingUsersText: ,
        // });
        setShowPendingUsersPopup(true);
        setSelctedType("");
        setShowPendingUsersText(resp?.errors[0]);
      }
    } else if (selctedType == "Reject") {
      setShowRejectUserUnputPopup(true);
      // this.setState({ showRejectUserUnputPopup: true });
    }
  };
  const clickConformRejectAction = async (comment: any) => {
    if (comment != "") {
      setShowRejectUserUnputPopup(false);
      // this.setState({ showRejectUserUnputPopup: false });
      // const { selctedType, selectedPendingUser } = this.state;
      const body = {
        id: selectedPendingUser?.id,
        isApproved: false,
        comments: comment,
      };
      const resp = (await dispatch(customerRejectCall(body))) as any;
      if (resp?.payload?.id) {
        // this.setState({
        //   showPendingUsersPopup: true,
        //   selctedType: "",
        //   showPendingUsersText: "This user has been rejected.",
        // });
        setShowPendingUsersPopup(true);
        setSelctedType("");
        setShowPendingUsersText("This Store has been rejected.");

        getAllUsersData();
      }
    }
  };
  const eachPageNumberClick = (pageno: any, type: any) => {};
  const cancelRejectPopup = () => {
    setShowRejectUserUnputPopup(false);
    setShowPendingUsersPopup(true);
    setSelctedType("");
    setShowPendingUsersText("This Store has not been rejected.");
    // this.setState({ showRejectUserUnputPopup: false });
    // this.setState({
    //   showPendingUsersPopup: true,
    //   selctedType: "",
    //   showPendingUsersText: "This user has not been rejected.",
    // });
  };

  const approvedpages = [];
  for (let i = 1; i <= Math.ceil(approvedTotalCount / usersPerPage); i++) {
    approvedpages.push(i);
  }
  const approvedrenderPageNumbers = approvedpages.map((number) => {
    if (
      number < approvedmaxPageNumberLimit + 1 &&
      number > approvedminPageNumberLimit
    ) {
      return (
        <li
          key={number}
          onClick={() => {
            eachPageNumberClick(number, "approved");
          }}
          className={approvedcurrentPage == number ? "active" : "null"}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });
  const pendingpages = [];
  for (let i = 1; i <= Math.ceil(pendingTotalCount / usersPerPage); i++) {
    pendingpages.push(i);
  }

  const rejectedpages = [];
  for (let i = 1; i <= Math.ceil(rejectedTotalCount / usersPerPage); i++) {
    rejectedpages.push(i);
  }
  const todayReports1 = allStoresReport
    .slice()
    .sort((a: any, b: any) => b.totalJobcards - a.totalJobcards);
  console.log("todayReports1", todayReports1);
  return (
    <>
      <EmailsReportStyled>
        <div className="USerMangement-wrapper">
          {showAllUsers && (
            <div className="userslIstManagement">
              <Row className="umHeadRow">
                <span className="headerName">Store Reports</span>
              </Row>
              <div className="Usertable-info Usertable-info-table">
                {loading ? (
                  <>
                    <div className="spinner-borderWord">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </>
                ) : (
                  <table>
                    <thead>
                      <tr className="headerNamesList">
                        <th>S No Name</th>
                        <th>Store ID</th>
                        <th>Store Name</th>
                        <th>Store Email</th>
                        <th>Email Type</th>
                        <th>Email Sent Date and Time</th>
                        <th>Customer Name</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(todayReports1 || []).map((user: any, key: any) => {
                        return (
                          <>
                            {" "}
                            <tr className="RowLevelClass bodyRowTextStyles">
                              <th>{key + 1}</th>

                              <th>{"SEP00001"}</th>

                              <td onClick={() => selectUserInfo(user)}>
                                {" "}
                                {"user.storeName"}{" "}
                              </td>
                              <th>{"store@gmail.com"}</th>
                              <th className="type-sec-color">
                                {key === 0
                                  ? "Email Verification"
                                  : key === 1
                                  ? "Store Approved"
                                  : key === 2
                                  ? "Welcome Email"
                                  : key === 3
                                  ? "Daily status report"
                                  : key === 4
                                  ? "Create Job card"
                                  : key === 5
                                  ? "Close Job card"
                                  : "Create Job card"}
                              </th>
                              <th>{`8th Apr 2024 8:30 PM`}</th>
                              <th>{"Vinod Tana"}</th>
                              <td onClick={() => selectUserInfo(user)}>
                                {`Success`}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          )}

          <CustomModal
            show={showBlockUserPopup}
            titleText="Are you sure you want to block the Store?"
            deleteText="Yes"
            cancelText="No"
            onCancel={() => hideModel()}
            onDelete={() => blockUserConform()}
          />
          <CustomModal
            show={showBlockSuccessUserPopup}
            titleText="You have blocked Store Successfully."
            deleteText="ok"
            cancelText=""
            onCancel={() => hideModel()}
            onDelete={() => hideModel()}
          />
          <CustomModal
            show={showUnBlockUserPopup}
            titleText="Are you sure you want to Unblock the Store?"
            deleteText="Yes"
            cancelText="No"
            onCancel={() => hideModel()}
            onDelete={() => unBlockUserConform()}
          />
          <CustomModal
            show={showUnBlockSuccessUserPopup}
            titleText="You have Unblocked Store Successfully."
            deleteText="ok"
            cancelText=""
            onCancel={() => hideModel()}
            onDelete={() => hideModel()}
          />
          <CustomModal
            show={showPendingUsersPopup}
            titleText={showPendingUsersText}
            deleteText={selctedType != "" ? selctedType : "OK"}
            cancelText={selctedType != "" ? "Cancel" : ""}
            onCancel={() => hideModel()}
            onDelete={() => clickConformAction()}
          />
        </div>
      </EmailsReportStyled>{" "}
    </>
  );
};
export default EmailsReport;
