import React, { useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import Sidebar from "./../../components/SideMenu/Sidebar";
import { useDispatch, useSelector } from "react-redux";

import "./antdstyles.css";
import "./common.css";
import "./bodyLayout.css";
const Layout = (props) => {
  useEffect(() => {}, []);
  const { error, loading, user } = useSelector(({ auth }) => auth);
  console.log("user", user);
  console.log("window.location.href", window.location.href);
  console.log("window.location.href", window.location.href);
  const urlPath = window.location.href;
  const isPrintPage = urlPath.includes("printJobCard");
  const isLoginPage = urlPath.includes("login");
  const isSignupPage = urlPath.includes("signup");
  const cUrl = window.location.pathname.toLowerCase();

  console.log("cUrl", cUrl);
  const showSideBar =
    user?.id &&
    cUrl !== "/" &&
    cUrl !== "/aboutus" &&
    cUrl !== "/contactus" &&
    cUrl !== "/home" &&
    cUrl !== "/checkjobcardstatus" &&
    cUrl !== "/termsofservice" &&
    cUrl !== "/privacypolicy" &&
    cUrl !== "/services";

  return (
    <div className={"wrapper-hide-header"}>
      {isPrintPage ? (
        <div className={isPrintPage ? "print-page" : ""}>
          <div className="layout-container1">{props.children}</div>
        </div>
      ) : (
        <>
          <Header />
          {showSideBar ? (
            <div className="body-layout-container-sec  layout-container">
              <div className="left-menu-container">
                <Sidebar />
              </div>
              <div className="body-layout-container">
                <div className="body-sec-content-full">{props.children}</div>
                {/* <div>
                  <Footer />
                </div> */}
              </div>
            </div>
          ) : (
            <div className="body-layout-container-sec layout-container2">
              <div className="body-sec-content-full">{props.children}</div>
              <div>
                <Footer />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Layout;
