import { ImageUploadModelStyled } from "./ImageUploadModel.styled";
import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import ImageCropper from "./ImageCropper";
import Loader from "./../../../components/Common/Loader";
import Webcam from "react-webcam";

import moment from "moment";
import AWS from "aws-sdk";

import Modal, { ModalProps } from "react-bootstrap/Modal";
import clsx from "clsx";
import CloseButton from "react-bootstrap/CloseButton";
import { useRef } from "react";

const ImageUploadModel = (props: any) => {
  const history = useHistory();
  const [passError, setPassError] = useState("");
  const dispatch = useDispatch();
  const hiddenFileInput = useRef<any>();
  const tagTabRef = useRef<any>();
  const [upImg, setUpImg] = React.useState<any>();
  const [infoMsg, setInfoMsg] = React.useState<any>(null);
  const [isLoader, setIsLoader] = useState(false);

  const [btnDisable, setBtnDisable] = React.useState<any>(true);
  const [fileSelected, setFileSelected] = React.useState<any>();
  const [croppedImage, setCroppedImage] = React.useState<any>(undefined);
  const [videoSrc, setVideoSrc] = React.useState<any>(null);
  const [uploadfromWebcam, setUploadfromWebcam] = React.useState<any>(false);

  const [isCropImg, setIsCropImg] = React.useState<any>(null);
  const mille_sec = moment.now();
  const webcamRef = React.useRef(null) as any;
  const [imgSrc, setImgSrc] = React.useState(null) as any;

  const takePhoto = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    setIsCropImg(imageSrc);
  }, [webcamRef, setImgSrc]);

  useEffect(() => {
    if (props?.show) {
      setBtnDisable(true);
      setIsCropImg(null);
      setUploadfromWebcam(false);
    }
  }, [props?.show]);

  const handleClick = () => {
    // document.getElementById("image-upload-input-box").click();

    if (hiddenFileInput) {
      hiddenFileInput?.current?.click();
    }
  };
  const onSelectFile = (event: any) => {
    setBtnDisable(false);
    if (event.target.files && event.target.files.length > 0) {
      const imgFile = event.target.files[0];
      console.log("imgFile", imgFile);
      if (!imgFile.type.includes("image")) {
        setInfoMsg(imgFile.name + " is not an image");
        return;
      }
      if (imgFile.size > 10 * 1024 * 1024) {
        setInfoMsg("Image file size should be less than or equal to 10MB");
        return;
      }
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setIsCropImg(reader.result);
        setUpImg(reader.result);
        setBtnDisable(true);
      });
      reader.readAsDataURL(imgFile);
      setFileSelected(imgFile);
    }
  };
  const handleConfirmClick = () => {
    // if (isCropImg) {
    //   onHideImage({ file: croppedImage?.blob });
    //   setUpImg(croppedImage?.url);
    // } else {
    // onHideImage({ file: fileSelected, src: upImg });
    uploadFile(fileSelected);
    setUpImg(upImg);
    // }

    // setIsCropImg(null);
  };
  const handleCancelClick = () => {
    props?.handleClose();
  };
  const uploadFile = async (file: any) => {
    const S3_BUCKET = "rasko-images";
    const REGION = "ap-south-1";
    AWS.config.update({
      accessKeyId: "AKIATONLOEN5TGUIRP7R",
      secretAccessKey: "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",
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });
    // console.log("file", file);
    // const newname1 = fileSel;
    const newNames = uploadfromWebcam
      ? "web_cam_Photo"
      : fileSelected?.name?.replace(/[^a-zA-Z]/g, "");
    const dFile = `${mille_sec}_${newNames}.png`;

    var base64Data = uploadfromWebcam
      ? Buffer.from(imgSrc?.replace(/^data:image\/\w+;base64,/, ""), "base64")
      : null;

    const params = {
      Bucket: S3_BUCKET,
      Key: dFile,
      Body: uploadfromWebcam ? base64Data : fileSelected,
      ContentEncoding: "base64",
      ContentType: "image/jpeg",
    };
    console.log("params", params);
    setIsLoader(true);
    var upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        console.log("Uploading %");
      })
      .promise();
    await upload.then((err: any) => {
      setIsLoader(false);
      props?.handleImageUploadSuccess(dFile);
    });
  };

  // const onHideImage = async (file: any) => {
  // console.log("file", file);
  // console.log("croppedImage", croppedImage);
  // console.log("croppedImage?.url", croppedImage?.url);
  // console.log("croppedImage?.croppedImageUrl", croppedImage?.croppedImageUrl);
  // console.log("fileSelected", fileSelected);
  // uploadImageNew(file);
  // croppedImageUrl
  // uploadFile(fileSelected);
  // const resp2 = (await dispatch(getSignedUrlApi())) as any;
  // if (resp2?.payload?.data) {
  //   const myHeaders = new Headers({ "Content-Type": "image/*" });
  //   const response = await fetch(resp2?.payload?.data, {
  //     method: "PUT",
  //     headers: myHeaders,
  //     body: fileSelected,
  //   });

  //   console.log("response", response);
  //   // const photoConvertedToBlob = dataURIToBlob(profilePhotoResized);
  //   // await pushProfilePhotoToS3(daresp2?.payload?.data, fileSelected);
  // }

  //   let imgData = await uploadImageNew(file, resp2?.payload?.data);
  // }
  // console.log("resp2", resp2);

  // let imgData = await uploadImageNew(file);
  // };

  const requestCamAndStreamInVideo = async () => {
    setUploadfromWebcam(true);
  };

  console.log("isCropImg", isCropImg);

  return (
    <>
      <Modal {...props} size="sm" className="image-upload-model-box" centered>
        <div className="conformation-model">
          <ImageUploadModelStyled>
            <Modal.Body>
              {/* <span
                className="color-picker-close-icon"
                onClick={() => props.setShowImageUploadModel(false)}
              >
                x
              </span> */}
              {isLoader && <Loader />}
              <div className="content-image-upload-model-box">
                <div>
                  <h5>Upload Image</h5>
                </div>
                {!isCropImg ? (
                  <div className="upload-model-box-content">
                    {uploadfromWebcam ? (
                      <>
                        <div className="web-cam-video">
                          <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                          />
                          <div>
                            <button
                              className="take-photo-btn"
                              onClick={() => {
                                takePhoto();
                              }}
                            >
                              Take Photo
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="upload-web-cam-btn-sec">
                          <button
                            className="upload-web-cam-btn"
                            onClick={() => {
                              requestCamAndStreamInVideo();
                            }}
                          >
                            Take from Web camera
                          </button>
                        </div>

                        <div className="top-hea-sec-top">
                          {/* <p className="top-hea-sec">
                            Drop your file from anywhere or
                          </p> */}
                          <button
                            className="button-upload"
                            onClick={handleClick}
                          >
                            Upload Image
                          </button>
                          <input
                            type="file"
                            id="image-upload-input-box"
                            // onChange={handleChange}
                            accept="image/png, image/gif, image/jpeg"
                            onChange={onSelectFile}
                            ref={hiddenFileInput}
                            style={{ display: "none" }} // Make the file input element invisible
                          />
                          {/* <input placeholder="Upload A File" type="file" /> */}
                        </div>
                        <div className="top-hea-sec-content">
                          <ul>
                            <li>
                              Image Dimensions are Equal to (or) Greater Than
                              500X500px
                            </li>
                            <li>High resolution images (PNG, JPG)</li>
                            <li>Orientation of the image should be Portrait</li>
                          </ul>
                        </div>
                      </>
                    )}
                    {infoMsg && <p>{infoMsg}</p>}
                  </div>
                ) : (
                  <>
                    {/* 3.43 / 1 :  */}
                    <div className="image-upload-cropper">
                      {/* <ImageCropper
                        ratio={1 / 1}
                        imageToCrop={isCropImg}
                        isDefaultCrop={false}
                        onImageCropped={(croppedImage: any) => {
                          console.log("croppedImage", croppedImage);
                          setCroppedImage(croppedImage);
                          setBtnDisable(false);
                        }}
                      /> */}
                      <img src={isCropImg} />
                    </div>
                  </>
                )}
              </div>
            </Modal.Body>

            <Modal.Footer>
              <div className="new-option-body-actions">
                <button className="cancel-btn" onClick={handleCancelClick}>
                  Cancel
                </button>
                <button
                  disabled={!isCropImg}
                  className="confirm-btn"
                  onClick={handleConfirmClick}
                >
                  Confirm
                </button>
              </div>
            </Modal.Footer>
          </ImageUploadModelStyled>
        </div>
      </Modal>
    </>
  );
};
export default ImageUploadModel;
