import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons/lib";
import { SideMenuStyled } from "./SideMenu.styled";
import booking from "./../../img/booking.png";
import teamWorkImg from "./../../img/teamwork.png";
import amcmana from "./../../img/icons8-analytics-30.png";
import homeicon from "./../../img/house-black-silhouette-without-door.png";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { checkRolePermission, checkAccessMenu } from "./../../Scenes/common";
import {
  getJobcardSummaryApi,
  getFilteredjobcardsummaryApi,
  getFilteredjobcardsummaryApiPending,
  getJobCardDetailsApi,
  jobCardRejectCall,
  jobCardClsoeCall,
} from "./../../redux/slices/serviceManagement/serviceManagementService";

// import teamWorkImg from "./../../img/teamwork.png";
// import teamWorkImg from "./../../img/teamwork.png";
const Sidebar = (props: any) => {
  const [sidebar, setSidebar] = useState(false);
  const [pendingJobcardsCount, setPendingJobcardsCount] = useState(0);
  const { user } = useSelector((stateR: any) => stateR?.auth);

  const menuAcceFlag = checkAccessMenu();

  const dispatch = useDispatch();
  const history = useHistory();

  const showSidebar = () => setSidebar(!sidebar);
  useEffect(() => {
    allPendingApprovalJobs(1);
  }, []);
  //   left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  //   Home
  //   Service Management
  //   Lead Management
  //   Booking Management
  //   AMC Management
  //   Users List
  //  Change Password

  // Jobcard Summary
  // Create Job Card
  // Items Received List
  // Default IssuesOption List
  // Additional Requirement List
  // Brand List
  // Product List
  // Actiontaken List
  // Engineer Observation List
  // Customer Note List
  // Postal Code List
  // Area / Ward List
  console.log("props", props);
  const allPendingApprovalJobs = async (pageN: any) => {
    const paramsList = [] as any;
    const page222 = pageN;
    paramsList.push(`pageNo=${page222}`);
    paramsList.push(`status=Closing Approval`);
    const paramsV = paramsList.join("&");
    const resp1 = (await dispatch(
      getFilteredjobcardsummaryApiPending(paramsV)
    )) as any;
    console.log("noOfJobCardsnoOfJobCards", resp1);
    setPendingJobcardsCount(resp1?.payload?.noOfJobCards);
  };

  const menuLinks = [
    {
      title: "Home",
      id: "focusNextHome",
      icon: homeicon,
      sublinks: [],
      link: "/jobcardsummary",
    },
    {
      title: "Create Order",
      id: "focusNextHome",
      icon: homeicon,
      sublinks: [],
      link: "/createOrder",
    },
    {
      title: "Create Appointment",
      id: "CreateAppointment",
      icon: homeicon,
      sublinks: [],
      link: "/CreateAppointment",
    },
    {
      title: "Dashboard",
      id: "Dashboard",
      icon: homeicon,
      sublinks: [],
      link: "/Dashboard",
    },
    {
      title: "Users List",
      id: "focusNextUsersList",
      icon: teamWorkImg,
      sublinks: [],
      link: "/userslist",
    },
    {
      title: "Customers List",
      id: "focusNextCustomersList",
      icon: teamWorkImg,
      sublinks: [],
      link: "/customersList",
    },
    {
      title: "Print Page Setting",
      id: "PrintPageSetting",
      icon: teamWorkImg,
      sublinks: [],
      link: "/printpagesetting",
    },

    {
      title: "Change Password",
      id: "focusNextChangePassword",
      icon: teamWorkImg,
      sublinks: [],
      link: "/changepassword",
    },
  ];
  const gotoNewPage = (url: any) => {
    console.log("urlurlurl", url);
    if (
      url === "/createjobcard" ||
      url === "/jobcardsummary" ||
      url === "/CreateLead"
    ) {
      window.location.href = url;
    } else if (url) {
      history.push(url);
    }
  };
  const selectedUrl = window.location.pathname;
  return (
    <>
      <SideMenuStyled>
        <div className="accordion side-bar-container" id="accordionExample">
          {menuLinks.map((item: any, index: any) => {
            // console.log("itemitem", item);
            const idx = item?.sublinks?.findIndex(
              (u: any) => u?.link === selectedUrl
            );
            const isOpenSec = idx !== -1 ? false : false;
            // console.log("selectedUrl", selectedUrl);
            // console.log("checkRolePermission");
            return (
              <>
                {checkRolePermission(item?.id) && (
                  <div
                    className={`accordion-item ${item?.id} ${
                      !checkAccessMenu(item?.id)
                        ? ""
                        : "accordion-item-disabled-view"
                    }`}
                  >
                    <h2 className="accordion-header" id={item?.id}>
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        title={
                          !checkAccessMenu(item?.id)
                            ? item?.title
                            : "Coming Soon"
                        }
                        disabled={checkAccessMenu(item?.id)}
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${item?.id}`}
                        aria-expanded={isOpenSec ? true : false}
                        aria-controls={`collapse${item?.id}`}
                        onClick={() => {
                          gotoNewPage(item?.link);
                        }}
                      >
                        {item?.icon && <img src={item?.icon} />} {item?.title}{" "}
                        {item?.id === "focusNextjobCardsApproval" && (
                          <>
                            <span className="job-card-sec-approval-count">
                              {` `}({pendingJobcardsCount})
                            </span>{" "}
                          </>
                        )}
                      </button>
                    </h2>
                    {item?.sublinks?.length > 0 && (
                      <div
                        id={`collapse${item?.id}`}
                        className={
                          isOpenSec
                            ? "accordion-collapse collapse show"
                            : "accordion-collapse collapse"
                        }
                        aria-labelledby={item?.id}
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body accordion-body-items">
                          <ul>
                            {item?.sublinks?.map((linkItem: any) => {
                              let newJson =
                                linkItem?.itemId || linkItem?.id || item?.id;
                              if (
                                item?.id === "focusNextLeadManagement" ||
                                item?.id === "focusNextAMCManagement"
                              ) {
                                newJson = item?.id;
                              }
                              return (
                                <>
                                  {checkRolePermission(newJson) && (
                                    <li
                                      onClick={() => {
                                        gotoNewPage(linkItem?.link);
                                      }}
                                    >
                                      <a
                                        href="#"
                                        className={
                                          selectedUrl === linkItem?.link
                                            ? "selectedItem"
                                            : ""
                                        }
                                      >
                                        <span>{linkItem?.title}</span>{" "}
                                      </a>
                                    </li>
                                  )}
                                </>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </>
            );
          })}
        </div>
      </SideMenuStyled>
    </>
  );
};

export default Sidebar;
