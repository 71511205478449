import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { signUpUser, addNewUser } from "../../../redux/slices/auth/authService";
import { useHistory, useLocation } from "react-router-dom";
import { GOOGLE_MAP_URL } from "./../../../config";
import CustomModal from "./../../../components/CustomModel";
import { triggerMixpanelEvent } from "../../../Scenes/common";

// import "./styles.css";
const initVal = {
  firstName: undefined,
  lastName: undefined,
  mobileNo: undefined,
  username: undefined,
  password: undefined,
  conformPassword: undefined,
  companyName: undefined,
  GSTNo: undefined,
  // officeNo: undefined,
  city: undefined,
  officeEmail: undefined,
  PANNo: undefined,
  companyRegNo: undefined,
  userType: undefined,
};

const initValStore = {
  storeName: undefined,
  storeEmail: undefined,
  storeMobileNo: undefined,
  storeWebsite: undefined,
  storeGSTNumber: undefined,
  productsDealingWith: undefined,
  address: undefined,
  contactName: undefined,
  personDesignation: undefined,
  contactNumber: undefined,
  email: undefined,
  password: undefined,
  conformPassword: undefined,
};

// const initValError = {
//   firstName: false,
//   lastName: false,
//   mobileNo: false,
//   username: false,
//   password: false,
//   conformPassword: false,
//   companyName: false,
//   GSTNo: false,
//   // officeNo: false,
//   city: false,
//   officeEmail: false,
//   PANNo: false,
//   companyRegNo: false,
//   userType: false,
// };
const initValError = {
  storeName: undefined,
  storeEmail: undefined,
  // storeMobileNo: undefined,
  // address: undefined,
  // contactName: undefined,
  contactNumber: undefined,
  // email: undefined,
  password: undefined,
  conformPassword: undefined,
  // userType: false,
};
const initValAddUserError = {
  firstName: false,
  lastName: false,
  mobileNo: false,
  username: false,
  password: false,
  conformPassword: false,
  userType: false,
  // officeNo: false,
};

const initValErrorData = {
  firstName: "Please Enter First name",
  lastName: "Please Enter Last name",
  mobileNo: "Please Enter Phone number",
  username: "Please Enter valid email",
  password: "Please Enter Password",
  conformPassword: "Please Enter Confirm Password",
  companyName: "Please Enter Company Name",
  GSTNo: "Please Enter GST No",
  // officeNo: "Please Enter Office No",
  city: "Please select the City name",
  officeEmail: "Please Enter Office Email",
  PANNo: "Please Enter PAN No",
  companyRegNo: "Please Enter Company Reg No",
};

const SignUpTab = (props) => {
  const { addUser, selectedData } = props;
  const [signUpData, setSignUpData] = useState(
    addUser ? initVal : initValStore
  );
  const [errorData, setErrorData] = useState(initValError);
  console.log("errorData", errorData);
  console.log("addUser", addUser);
  const [errorDataValue, setErrorDataValue] = useState(initValErrorData);
  const { error, loading, user } = useSelector(({ auth }) => auth);
  const history = useHistory();
  const [passError, setPassError] = useState(null);
  const [showSuccessUserPopup, setShowSuccessUserPopup] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.data?._id) {
      history.push("/signin");
    }
  }, [user]);
  useEffect(() => {
    onLoadGoogleMap();
    if (user?.id) {
      history.push("/jobcardsummary");
    }
  }, []);

  useEffect(() => {
    if (selectedData?.id) {
      const prevVJson = { ...signUpData };
      prevVJson.id = selectedData?.id;
      prevVJson.firstName = selectedData?.firstName;
      prevVJson.lastName = selectedData?.lastName;
      prevVJson.adharCardNo = selectedData?.adharCardNo;
      prevVJson.mobileNo = selectedData?.mobileNo;
      prevVJson.username = selectedData?.username;
      prevVJson.userType = selectedData?.userType;
      const initValError = {
        firstName: false,
        lastName: false,
        mobileNo: false,
        username: false,
        userType: false,
      };
      // setErrorData(initValError);
      setSignUpData(prevVJson);
    }
  }, [selectedData]);
  const onLoadGoogleMap = () => {
    const script = document.createElement("script");
    script.src = GOOGLE_MAP_URL;
    script.id = "googleMaps";
    document.body.appendChild(script);
    script.onload = () => {
      handleScriptLoad();
    };
  };
  const handleScriptLoad = () => {
    try {
      const google = window.google;
      if (document.getElementById("addCreateUserAdress1")) {
        const options = {
          componentRestrictions: { country: "ind" },
        };
        const autocomplete = new google.maps.places.Autocomplete(
          document.getElementById("addCreateUserAdress1"),
          options
        );
        autocomplete.setFields(["address_components", "formatted_address"]);
        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          handlePlaceSelect(place);
        });
      }
    } catch (e) {
      console.log("e", e);
    }
  };
  const handlePlaceSelect = (addressObject) => {
    var address1 = "";
    let postcode = "";
    let city = "";
    let state = "";
    let address2 = "";
    let address21 = "";
    let country = "";
    console.log("addressObject", addressObject);
    let fullAddress = addressObject?.formatted_address;
    for (const component of addressObject?.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number": {
          address1 = `${component.long_name}`;
          break;
        }
        case "route": {
          address2 = component.short_name;
          break;
        }
        case "postal_code": {
          postcode = `${component.long_name}${postcode}`;
          break;
        }
        case "locality":
          city = component.long_name;
          break;
        case "country":
          country = component.long_name;
          break;
        case "administrative_area_level_1": {
          state = component.long_name;
          break;
        }
        case "sublocality_level_1": {
          address21 = component.long_name;
          break;
        }
      }
    }
    const AddressLine1 = address1 + " " + address2;

    const stateD = getPrevData();
    stateD["address"] = fullAddress;
    stateD["address1"] = AddressLine1;
    stateD["address2"] = address21;
    stateD["city"] = city;
    stateD["state"] = state;
    // stateD["county"] = country;
    stateD["pinCode"] = postcode;

    setSignUpData(stateD);
  };
  const getPrevData = () => {
    const initValStorePrev = {
      storeName: undefined,
      storeEmail: undefined,
      storeMobileNo: undefined,
      storeWebsite: undefined,
      storeGSTNumber: undefined,
      productsDealingWith: undefined,
      contactName: undefined,
      personDesignation: undefined,
      contactNumber: undefined,
      email: undefined,
      password: undefined,
      conformPassword: undefined,
    };
    const prevVaaa = { ...signUpData };
    for (var key in initValStorePrev) {
      if (document.getElementById(key).value) {
        prevVaaa[key] = document.getElementById(key).value;
      }
    }

    console.log("signUpData prevVaaa", prevVaaa);
    return prevVaaa;
  };
  const handleChangeValue = (e) => {
    setPassError("");
    let { name, value } = e.target;
    var value1 = value;
    if (value.trim() == "") {
      value1 = value.trim();
    }
    const addr = { ...signUpData };
    const addr_error = { ...errorData };
    if (
      name == "mobileNo" ||
      name == "officeNo" ||
      name == "storeMobileNo" ||
      name == "contactNumber"
    ) {
      const re = /^[0-9\b]+$/;
      if (value1 === "" || re.test(value1)) {
        setSignUpData({
          ...addr,
          [name]: value1,
        });
        if (value1?.length > 9) {
          setErrorData({
            ...addr_error,
            [name]: false,
          });
        }
      }
    } else if (
      name === "username" ||
      name == "officeEmail" ||
      name == "storeEmail" ||
      name == "email"
    ) {
      setSignUpData({
        ...addr,
        [name]: value1,
      });
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailRegex.test(String(value).toLowerCase())) {
        setErrorData({
          ...addr_error,
          [name]: false,
        });
      } else {
        setErrorData({
          ...addr_error,
          [name]: true,
        });
      }
    } else {
      setSignUpData({
        ...addr,
        [name]: value1,
      });
      setErrorData({
        ...addr_error,
        [name]: false,
      });
    }
  };
  const createAccountData = () => {
    setPassError(null);
    var isValid = true;
    var errornew = { ...errorData };
    for (var key in errorData) {
      const pValue = signUpData[key];
      if (signUpData[key] === "" || !signUpData[key]) {
        errornew[key] = true;
        isValid = false;
      }
      if (key === "contactNumber" && pValue?.length < 10) {
        errornew[key] = true;
        isValid = false;
      }
    }
    console.log("errornew", errornew);
    setErrorData({ ...errornew });
    if (!selectedData?.id) {
      if (signUpData?.password !== signUpData?.conformPassword) {
        isValid = false;
        setPassError(
          "Password and Confirm Password fields value must be matched."
        );
      } else if (signUpData?.password?.length < 6) {
        isValid = false;
        setPassError("Password requires 6 characters minimum.");
      }
    }
    if (isValid) {
      makeSignUpCall(signUpData);
      console.log("signUpData 444", signUpData);
    }
  };
  const makeSignUpCall = async (data) => {
    if (addUser) {
      dispatch(addNewUser(data));
    } else {
      triggerMixpanelEvent("create_accout_click");
      const resp = await dispatch(signUpUser(data));
      console.log("respresp", resp);
      if (resp?.payload?.id) {
        triggerMixpanelEvent("create_accout_success");
        setShowSuccessUserPopup(true);
      }
    }

    console.log("vinodddd");
  };
  const hideModel = () => {
    history.push("/signin");
    setShowSuccessUserPopup(false);
  };
  const validationCheck = () => {
    var formValid = false;
    console.log("signUpData", signUpData);
    console.log("errorData", errorData);
    for (var key in errorData) {
      if (signUpData[key] === "" || !signUpData[key]) {
        formValid = true;
      }
    }
    return formValid;
  };
  const allCitys = [
    { id: 45, name: "Bangalore" },
    { id: 45, name: "Hyderabad" },
    { id: 45, name: "Mumbai" },
    { id: 45, name: "Delhi" },
  ];
  const userRoles = [
    { label: "ADMIN", value: "ADMIN" },
    { label: "SUPER_ADMIN", value: "SUPER ADMIN" },
    { label: "ADMIN", value: "ENGINEER" },
    { label: "FREELANCER", value: "ENGINEER" },
  ];

  console.log("signUpData final", JSON.stringify(signUpData));
  console.log("errorData?.email", errorData);
  return (
    <div className="SignupContainer-signup">
      {addUser ? (
        <>
          <Form.Group className="signup-md" size="lg" controlId="email">
            <Form.Label>
              First Name <span className="mand-sign-field">*</span>{" "}
            </Form.Label>
            <Form.Control
              autoFocus
              name="firstName"
              type="text"
              value={signUpData?.firstName}
              onChange={(e) => handleChangeValue(e)}
            />
            <Form.Control.Feedback type="invalid">
              {errorData?.firstName ? <>{errorDataValue?.firstName}</> : null}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="signup-md-left" size="lg" controlId="email">
            <Form.Label>
              Last Name <span className="mand-sign-field">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              value={signUpData?.lastName}
              onChange={(e) => handleChangeValue(e)}
            />
            <Form.Control.Feedback type="invalid">
              {errorData?.lastName ? <>{errorDataValue?.lastName}</> : null}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="signup-md" size="lg" controlId="email">
            <Form.Label>
              Phone Number <span className="mand-sign-field">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              maxLength={10}
              name="mobileNo"
              value={signUpData?.mobileNo}
              onChange={(e) => handleChangeValue(e)}
            />
            <Form.Control.Feedback type="invalid">
              {errorData?.mobileNo ? <>{errorDataValue?.mobileNo}</> : null}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="signup-md-left" size="lg" controlId="email">
            <Form.Label>
              Email <span className="mand-sign-field">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="username"
              value={signUpData?.username}
              onChange={(e) => handleChangeValue(e)}
            />
            <Form.Control.Feedback type="invalid">
              {errorData?.username ? <>{errorDataValue?.username}</> : null}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="signup-md" size="lg" controlId="email">
            <Form.Label>Adhar No</Form.Label>
            <Form.Control
              type="text"
              maxLength={10}
              name="adharCardNo"
              value={signUpData?.adharCardNo}
              onChange={(e) => handleChangeValue(e)}
            />
            <Form.Control.Feedback type="invalid">
              {errorData?.adharCardNo ? (
                <>{errorDataValue?.adharCardNo}</>
              ) : null}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="signup-md-left" size="lg" controlId="role">
            <Form.Label>Select Role</Form.Label>

            <Form.Control
              name="userType"
              // isInvalid={x.cityError}
              value={signUpData?.userType}
              onChange={(e) => handleChangeValue(e)}
              as="select"
            >
              <option name="">Select</option>
              {(userRoles || []).map((item, key) => {
                return (
                  <>
                    <option
                      selected={
                        item?.value === signUpData?.userType ? true : false
                      }
                      value={item?.value}
                    >
                      {item?.label}
                    </option>
                  </>
                );
              })}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errorData?.userType ? <>{errorDataValue?.userType}</> : null}
            </Form.Control.Feedback>
          </Form.Group>
          {!selectedData?.id && (
            <div className="password-fields-sec">
              <Row>
                <Form.Group
                  className="signup-md"
                  size="lg"
                  controlId="password"
                >
                  <Form.Label>
                    Password<span className="mand-sign-field">*</span>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    isInvalid={errorData?.password}
                    value={signUpData?.password}
                    onChange={(e) => handleChangeValue(e)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorData?.password ? (
                      <>{errorDataValue?.password}</>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="signup-md-left"
                  size="lg"
                  controlId="password"
                >
                  <Form.Label>
                    Confirm Password<span className="mand-sign-field">*</span>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="conformPassword"
                    value={signUpData?.conformPassword}
                    onChange={(e) => handleChangeValue(e)}
                  />
                </Form.Group>
              </Row>
            </div>
          )}
        </>
      ) : (
        <>
          {" "}
          {/* <div>Sign UP</div> */}
          <Form.Group className="signup-md" size="lg">
            <Form.Label>
              Store Name / Business Name{" "}
              <span className="mand-sign-field">*</span>{" "}
            </Form.Label>
            <Form.Control
              autoFocus
              name="storeName"
              id="storeName"
              isInvalid={errorData?.storeName}
              type="text"
              value={signUpData?.storeName}
              onChange={(e) => handleChangeValue(e)}
            />
            <Form.Control.Feedback type="invalid">
              {errorData?.storeName ? <>{errorDataValue?.storeName}</> : null}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="signup-md-left" size="lg">
            <Form.Label>
              Business Email <span className="mand-sign-field">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="storeEmail"
              id="storeEmail"
              isInvalid={errorData?.storeEmail}
              value={signUpData?.storeEmail}
              onChange={(e) => handleChangeValue(e)}
            />
            <Form.Control.Feedback type="invalid">
              {errorData?.storeEmail ? <>{errorDataValue?.storeEmail}</> : null}
            </Form.Control.Feedback>
          </Form.Group>
          {/* <Form.Group className="signup-md" size="lg">
            <Form.Label>
              Store Phone Number <span className="mand-sign-field">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              maxLength={10}
              name="storeMobileNo"
              isInvalid={errorData?.storeMobileNo}
              id="storeMobileNo"
              value={signUpData?.storeMobileNo}
              onChange={(e) => handleChangeValue(e)}
            />
            <Form.Control.Feedback type="invalid">
              {errorData?.storeMobileNo ? (
                <>{errorDataValue?.storeMobileNo}</>
              ) : null}
            </Form.Control.Feedback>
          </Form.Group> */}
          {/* <Form.Group className="signup-md-left" size="lg">
            <Form.Label>Website</Form.Label>
            <Form.Control
              type="text"
              maxLength={200}
              name="storeWebsite"
              id="storeWebsite"
              value={signUpData?.storeWebsite}
              onChange={(e) => handleChangeValue(e)}
            />
            <Form.Control.Feedback type="invalid">
              {errorData?.storeWebsite ? (
                <>{errorDataValue?.storeWebsite}</>
              ) : null}
            </Form.Control.Feedback>
          </Form.Group> */}
          {/* <Form.Group className="signup-md" size="lg">
            <Form.Label>GST Number</Form.Label>
            <Form.Control
              type="text"
              maxLength={20}
              name="storeGSTNumber"
              id="storeGSTNumber"
              value={signUpData?.storeGSTNumber}
              onChange={(e) => handleChangeValue(e)}
            />
            <Form.Control.Feedback type="invalid">
              {errorData?.storeGSTNumber ? (
                <>{errorDataValue?.storeGSTNumber}</>
              ) : null}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="signup-md-left" size="lg">
            <Form.Label>Products Dealing with</Form.Label>
            <Form.Control
              type="text"
              maxLength={200}
              name="productsDealingWith"
              id="productsDealingWith"
              placeholder="Laptop, Printer, Mobile ...etc"
              value={signUpData?.productsDealingWith}
              onChange={(e) => handleChangeValue(e)}
            />
            <Form.Control.Feedback type="invalid">
              {errorData?.productsDealingWith ? (
                <>{errorDataValue?.productsDealingWith}</>
              ) : null}
            </Form.Control.Feedback>
          </Form.Group> */}
          {/* <Form.Group className="signup-md" size="lg" controlId="companyName">
            <Form.Label>
              Contact Name <span className="mand-sign-field">*</span>{" "}
            </Form.Label>
            <Form.Control
              type="text"
              maxLength={50}
              name="contactName"
              isInvalid={errorData?.contactName}
              id="contactName"
              value={signUpData?.contactName}
              onChange={(e) => handleChangeValue(e)}
            />
            <Form.Control.Feedback type="invalid">
              {errorData?.contactName ? (
                <>{errorDataValue?.contactName}</>
              ) : null}
            </Form.Control.Feedback>
          </Form.Group> */}
          <Form.Group
            className="signup-md-left"
            size="lg"
            controlId="contactNumber"
          >
            <Form.Label>
              Contact Number <span className="mand-sign-field">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              maxLength={10}
              name="contactNumber"
              isInvalid={errorData?.contactNumber}
              id="contactNumber"
              value={signUpData?.contactNumber}
              onChange={(e) => handleChangeValue(e)}
            />
            <Form.Control.Feedback type="invalid">
              {errorData?.contactNumber ? (
                <>{errorDataValue?.contactNumber}</>
              ) : null}
            </Form.Control.Feedback>
          </Form.Group>
          {/* <Form.Group className="signup-md-full">
            <Form.Label>
              Address <span className="mand-sign-field">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              id="addCreateUserAdress1"
              maxLength={100}
              name="address"
              isInvalid={errorData?.address}
              value={signUpData?.address}
              onChange={(e) => handleChangeValue(e)}
            />
            <Form.Control.Feedback type="invalid">
              {errorData?.address ? <>{errorDataValue?.address}</> : null}
            </Form.Control.Feedback>
          </Form.Group> */}
          {/* <Form.Group className="signup-md" size="lg">
            <Form.Label>
              Email Id
              <span className="mand-sign-field">*</span>
            </Form.Label>

            <Form.Control
              name="email"
              id="email"
              type="text"
              isInvalid={errorData?.email}
              placeholder="email"
              // isInvalid={x.emailError}
              value={signUpData?.email}
              onChange={(e) => handleChangeValue(e)}
              // as="select"
            ></Form.Control>
            <Form.Control.Feedback type="invalid">
              {errorData?.email ? <>{errorDataValue?.email}</> : null}
            </Form.Control.Feedback>
          </Form.Group> */}
          {/* <Form.Group
            className="signup-md-left "
            size="lg"
            controlId="personDesignation"
          >
            <Form.Label>Person Designation</Form.Label>
            <Form.Control
              type="text"
              maxLength={100}
              name="personDesignation"
              id="personDesignation"
              value={signUpData?.personDesignation}
              onChange={(e) => handleChangeValue(e)}
            />
            <Form.Control.Feedback type="invalid">
              {errorData?.personDesignation ? (
                <>{errorDataValue?.personDesignation}</>
              ) : null}
            </Form.Control.Feedback>
          </Form.Group> */}
          <div className="password-fields-sec">
            <Row>
              <Form.Group className="signup-md" size="lg" controlId="password">
                <Form.Label>
                  Password<span className="mand-sign-field">*</span>
                </Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  id="password"
                  isInvalid={errorData?.password}
                  value={signUpData?.password}
                  onChange={(e) => handleChangeValue(e)}
                />
                <Form.Control.Feedback type="invalid">
                  {errorData?.password ? <>{errorDataValue?.password}</> : null}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="signup-md-left"
                size="lg"
                controlId="password"
              >
                <Form.Label>
                  Confirm Password<span className="mand-sign-field">*</span>
                </Form.Label>
                <Form.Control
                  type="password"
                  name="conformPassword"
                  isInvalid={errorData?.conformPassword}
                  id="conformPassword"
                  value={signUpData?.conformPassword}
                  onChange={(e) => handleChangeValue(e)}
                />
              </Form.Group>
            </Row>
          </div>
        </>
      )}
      {passError && <p className="errorMessage">{passError}</p>}
      {error && !passError && <p className="errorMessage">{error}</p>}

      <div className="create-btn-full-width">
        <div className="create-account-action-con">
          <button
            // disabled={validationCheck()}
            onClick={() => {
              createAccountData();
            }}
            block
            size="lg"
            type="submit"
            className="create-account-btn-sec"
          >
            {loading
              ? "Loading"
              : selectedData?.id
              ? "Update"
              : "Create Account"}
          </button>
        </div>
      </div>
      <CustomModal
        show={showSuccessUserPopup}
        titleText="You have Created Successfully Store, We will send an email once Admin is Approved."
        deleteText="ok"
        cancelText=""
        onCancel={() => hideModel()}
        onDelete={() => hideModel()}
      />
      {!addUser && (
        <div className="dont-have-acc">
          Already have an account? <a href="/signin">Sign in</a>
        </div>
      )}
    </div>
  );
};

export default SignUpTab;
