import { useEffect, useState } from "react";

import { SignUpStyled } from "./SignUp.styled";
import SignUpTab from "./SignUpTab";
import Logo from "../../img/focusNext.png";
import { triggerMixpanelEvent } from "../../Scenes/common";

import "./styles.css";
const SignUp = () => {
  useEffect(() => {
    triggerMixpanelEvent("signup_page");
  }, []);
  return (
    <>
      <SignUpStyled>
        <div className="signup-sec-full-con">
          <div className="signup-container">
            <div className="store-sign-up">
              <div className="store-sign-up-img">
                {/* <img className="mainLogoImage" src={Logo}></img> */}
              </div>
              <h2>Botique</h2>
              <h4> Create New Store / Business</h4>
            </div>
            <div className="create-new-form">
              <SignUpTab />
            </div>
          </div>
        </div>
      </SignUpStyled>
    </>
  );
};
export default SignUp;
