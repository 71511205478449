import React, { useEffect, useState } from "react";
import { ContactUSStyled } from "./ContactUS.styled";
import {
  Row,
  Col,
  Button,
  Form,
  CardDeck,
  CardColumns,
  Dropdown,
} from "react-bootstrap";
import "./styles.css";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import ProductCard from "./../../components/ProductCard";
import moment from "moment";
import { triggerMixpanelEvent } from "./../../Scenes/common";
const ContactUS = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [thankyouMessage, setThankyouMessage] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyPhoneNumber, setEmptyPhoneNumber] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    triggerMixpanelEvent("Contact_Us");
  }, []);
  const formSubmitClick = async () => {
    setThankyouMessage("");
    if (name != "" && email != "" && phoneNumber != "" && errorEmail === "") {
      var bostStr = `name=${name},email=${email},phoneNumber=${phoneNumber},message=${message}, `;
      const respJson = { name, email, phoneNumber, message };
      triggerMixpanelEvent("submitted_", respJson);
      try {
        setName("");
        setEmail("");
        setPhoneNumber("");
        setMessage("");
        setThankyouMessage("Thank you, we will get back to you shortly.");

        // const body ={
        //   body: bostStr,
        //   subject: "Talk To Us Information "+name,
        //   to: "vinod@myorigami.co"
        // }
        // setIsLoading(true)
        // const response = await Axios.post("http://52.165.36.192:8080/api/Email/Send", body);
        //   console.log("vinod", response)
        //   setIsLoading(false)
        // if(response?.statusText === "OK"){
        //   setName("");setEmail("");setPhoneNumber("");setMessage("");
        //   setThankyouMessage("Thank you, we will get back to you shortly.")
        // }
        // return response.data;
      } catch (error) {
        console.log(error);
      }
    } else {
      if (name === "") setEmptyName(true);
      if (email === "") setEmptyEmail(true);
      if (phoneNumber === "") setEmptyPhoneNumber(true);
    }
  };

  const onChangeValue = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(e.target.value)) {
        setErrorEmail("Please enter valid email address.");
      } else {
        setErrorEmail("");
      }
    }
  };
  return (
    <>
      <ContactUSStyled>
        <div className="freshbag-wrapper">
          <div className="content getinTouchPage">
            <div className="contact">
              <div className="row">
                <div className="consultnew col-md-6">
                  <h1>At Boutique, Drive More Customers Through Digital</h1>
                  <p>
                    A complete services and repairs business operations
                    management solution. Create Job sheets, manage services and
                    generate invoices.A common module to manage both products
                    and spare parts inventory. Stock management, consumption
                    history and stock transfers.Simple and sophisticated sales
                    billing solution. Invoice generation and payment details.
                  </p>
                </div>
                <div className="consultnew col-md-6">
                  <h2>Contact Us</h2>
                  <h6>Let's discuss your plans and goals</h6>
                  <div className="contactformnew">
                    <input
                      type="text"
                      className={emptyName ? "error" : ""}
                      onChange={(e) => {
                        setName(e.target.value);
                        setEmptyName(false);
                      }}
                      id="name"
                      value={name}
                      name="name"
                      placeholder="Name*"
                    ></input>
                    <input
                      type="text"
                      className={emptyEmail ? "error" : ""}
                      onChange={(e) => {
                        onChangeValue(e);
                        setEmptyEmail(false);
                      }}
                      id="email"
                      value={email}
                      name="email"
                      placeholder="Email*"
                    ></input>
                    {errorEmail != "" && (
                      <p className="emailError">{errorEmail}</p>
                    )}
                    <input
                      type="text"
                      className={emptyPhoneNumber ? "error" : ""}
                      maxLength={10}
                      onChange={(e) => {
                        setEmptyPhoneNumber(false);
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          setPhoneNumber(e.target.value);
                        }
                      }}
                      id="phoneNumber"
                      value={phoneNumber}
                      name="phoneNumber"
                      placeholder="Phone Number*"
                    ></input>
                    <textarea
                      type="text"
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                      id="message"
                      value={message}
                      name="message"
                      placeholder="Your Message"
                    ></textarea>

                    {thankyouMessage != "" && (
                      <p className="successText">{thankyouMessage}</p>
                    )}
                    <input
                      type="submit"
                      onClick={formSubmitClick}
                      value={isLoading ? "Loading..." : "Submit"}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContactUSStyled>
    </>
  );
};

export default ContactUS;
