import React, { Component, useEffect } from "react";
import PropTypes from "prop-types";
import "./styles.css";
import { bindActionCreators } from "redux";
import { Row, Col, Button, CardDeck, CardColumns } from "react-bootstrap";
// import 'bootstrap/dist/css/bootstrap.min.css';

import { Link, useHistory, withRouter } from "react-router-dom";
const Footer = (props) => {
  useEffect(() => {}, []);

  return (
    <>
      <div className="footer-wrapper">
        <div className="footer-container">
          {/* <div className="footer-top">
            <div className="foo-top-left">
              <h2>Need Urgent Repair Service?</h2>
            </div>
            <div className="foo-top-right">
              <button> Let's Talk </button>
            </div>
          </div> */}
          <div className="footer-bottom">
            <div className="foo-bottom-left">
              <div class="elementor-text-editor elementor-clearfix">
                <p>JP Nagar 7th Phase, Bangalore, India</p>
                <p>+91 9740091539</p>
                <p>contact@botique.in</p>
              </div>
            </div>
            <div className="foo-bottom-right">
              <div class="elementor-widget-container">
                <ul class="elementor-icon-list-items elementor-inline-items">
                  {/* <li class="li-item-list">
                    <a href="/home">
                      <span class="text-item">Home</span>
                    </a>
                  </li> */}
                  <li class="li-item-list">
                    <a href="/Services">
                      <span class="text-item">Product</span>
                    </a>
                  </li>
                  <li class="li-item-list">
                    <a href="/jobcardsummary">
                      <span class="text-item">Dashboard</span>
                    </a>
                  </li>
                  <li class="li-item-list">
                    <a href="/Services#pricingSection">
                      <span class="text-item">Pricing</span>
                    </a>
                  </li>

                  <li class="li-item-list">
                    <a href="/AboutUs">
                      <span class="text-item">About Us</span>
                    </a>
                  </li>
                  <li class="li-item-list">
                    <a href="/ContactUS">
                      <span class="text-item">Contact US</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="Privacy-Policy-sec">
          <div>
            <p>
              <a href="/TermsofService" target="_blank">
                Terms of Service
              </a>
            </p>
          </div>
          <div>
            <p>
              {" "}
              <a href="/PrivacyPolicy" target="_blank">
                Privacy Policy{" "}
              </a>{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="all-rights-reser">
        <span>© All rights reserved 2024.</span>
      </div>
    </>
  );
};

export default Footer;
